
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Array,
      default() {
        return [] as string[];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tagsList: {
      type: Array,
      default() {
        return [] as string[];
      },
      required: true,
    },
    tagsLimit: {
      type: Number,
      default: 3,
      required: true,
    },
    tagsHeader: {
      type: String,
    },
  },
  data(): any {
    return {
      activeTags: [] as string[],
    };
  },
  methods: {
    addNewTag(tag: string) {
      if (!this.disabled) {
        const selectedTag = this.activeTags.indexOf(tag);
        if (selectedTag > -1) {
          this.activeTags.splice(selectedTag, 1);
        } else if (this.activeTags == null || this.activeTags.length < this.tagsLimit) {
          this.activeTags.push(tag);
        }
        this.$emit('input', this.activeTags);
        this.$emit('auto-save');
      }
    },
  },
  mounted() {
    if (this.value.length > 0) {
      this.activeTags = this.value;
    }
  },
  watch: {
    value: function() {
      this.activeTags = this.value;
    },
  },
});
